import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// css
import './styles/global.css';

// components
import FullScreenLoader from './components/FullScreenLoader.jsx';
import NotFound from "./components/404NotFound";
import FooterAnimation from "./components/FooterAnimation.jsx";

// pages
// import Auth from './pages/Auth.jsx';
// import Website from './pages/website/Landing.jsx';

// lazy loading
const Website = React.lazy(() => import("./pages/website/Landing.jsx"));
// const ComingSoon = React.lazy(() => import("./pages/website/comingSoon/ComingSoon"));

function App() {
	return (
		<>
			<BrowserRouter>
				<Routes>
					{/* <Route 
						path="/" 
						element={
							<Suspense fallback={< FullScreenLoader />}>
								<ComingSoon />
							</Suspense>
						} 
					/> */}
					<Route 
						path="/" 
						element={
							<Suspense fallback={< FullScreenLoader />}>
								<Website />
							</Suspense>
						} 
					/>
					<Route 
						path="/web" 
						element={
							<Suspense fallback={< FullScreenLoader />}>
								<Website />
							</Suspense>
						} 
					/>
					<Route path="/footeranime" element={<FooterAnimation />} />
					{/* <Route path="/auth" element={<Auth />} /> */}
					<Route path="*" element={<NotFound />} />
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;
