import React from 'react';
// Importing Link from react-router-dom for navigation between pages
import { Link } from "react-router-dom";

// Importing CSS styles
import '../styles/website.css';

// Importing logo and door images
import AxipaysFull_logo from '../media/image/Logo-stroke.webp';
import Door from "../media/image/404-door.webp";

function Error() {
    return (
        <div className="error-container">
            {/* Header section with logo */}
            <div className="error-header">
                <img src={AxipaysFull_logo} alt="Axipays Logo" className="error-logo" />
            </div>

            <div className="error-body">
                {/* Decorative rectangles for visual effect */}
                {[...Array(9)].map((_, index) => (
                    <div key={index} className={`rectangle rectangle-${index + 1}`}></div>
                ))}

                {/* Error code with door image in place of the middle '0' */}
                <div className="digit-container">
                    <div className="digit">4</div>
                    <div className="digit door">
                        <img src={Door} alt="Door" className="door-image" />
                    </div>
                    <div className="digit">4</div>
                </div>

                {/* Overlay with error message and navigation link */}
                <div className="overlay">
                    <h1 className="error-title">We can't seem to find the page you are looking for.</h1>
                    <p>Please consider entering a valid URL.</p>
                    <p>This page does not exist.</p>
                    <Link to="/home">
                        <button className="primary-btn-bright">Back to Home Page</button>
                    </Link>
                </div>
            </div>

            {/* Footer section for additional content or styling, if needed */}
            <div className="error-footer"></div>
        </div>
    );
}

export default Error;
