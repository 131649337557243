import React from "react";

const RotatingCircles = () => {
  return (
    <div class="container">
  <div class="circles-wrapper">
  <div class="circle circle-vvlg">
  <div class="circle circle-vlg">
  <div class="circle circle-lg">
    <div class="circle circle-mn">
      <div class="circle circle-md">
        <div class="circle circle-sm"></div>
      </div>
    </div>
  </div>
</div>
</div>
  </div>
</div>
  );
};

export default RotatingCircles;
