import React from 'react';

// Importing the main CSS file for styling
import '../styles/website.css';

function FullScreenLoader() {
    return (
        // Container div for full-screen loader
        <div className='fullScreenLoaderContainer'>
            {/* Loader div styled to show a loading animation */}
            <div className='fullScreenLoader'></div>
        </div>
    );
}

export default FullScreenLoader;